var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-full min-contents" },
    [
      _c(
        "div",
        { staticClass: "container mx-auto" },
        [
          _vm.consumers && _vm.consumers.length > 0
            ? [
                _vm.consumers.length > 0
                  ? _c(
                      "div",
                      { staticClass: "grid grid-cols-12 gap-x-6 mb-4" },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "col-start-6 col-span-2 mt-4 px-4 py-2 mr-1 font-bold text-xs relative",
                            class: "text-black bg-very-light-grey",
                            on: {
                              click: function ($event) {
                                return _vm.changeSort()
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("pages.administrators.commons.sort")
                                ) +
                                " "
                            ),
                            _c("img", {
                              staticClass: "absolute sort-image sort_position",
                              class: [
                                _vm.sort_order
                                  ? "desc"
                                  : "transform rotate-180",
                              ],
                              attrs: {
                                src: require("@/assets/image/sort_icon.svg"),
                              },
                            }),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
          _c(
            "div",
            { ref: "divRef", staticClass: "contents_list my-10" },
            [
              _vm.consumers && _vm.consumers.length > 0
                ? _vm._l(_vm.consumers, function (consumer, key) {
                    return _c(
                      "div",
                      {
                        key: key,
                        staticClass:
                          "grid grid-cols-12 gap-x-6 border border-gray-500 h-11 mb-4 hover:bg-gray-200",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-span-2 border-r border-gray-500 font-bold text_item_center my-1",
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm._f("padZero")(consumer.id)) + " "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "xl:col-span-8 col-span-7 grid grid-cols-12 gap-x-3 text-xs",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "col-span-3 text_item_start" },
                              [
                                _c("img", {
                                  staticClass: "w-4 h-4 inline-block",
                                  attrs: {
                                    src: require("@/assets/image/icon_list-01.svg"),
                                  },
                                }),
                                _c("FlTruncatableSpan", {
                                  attrs: { value: consumer.company_name },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-span-3 text_item_start" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: "/admin/vods/" + consumer.uuid,
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "w-4 h-4 inline-block",
                                      attrs: {
                                        src: require("@/assets/image/icon_list-02.svg"),
                                      },
                                    }),
                                    _c("FlTruncatableSpan", {
                                      attrs: { value: consumer.name },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-span-3 text_item_start" },
                              [
                                _c("FlTruncatableSpan", {
                                  attrs: {
                                    value: _vm.createChannelsText(
                                      consumer.channels
                                    ),
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-span-3 text_item_start" },
                              [
                                _c("img", {
                                  staticClass: "w-4 h-4 inline-block",
                                  attrs: {
                                    src: require("@/assets/image/icon_list-04.svg"),
                                  },
                                }),
                                _c("FlTruncatableSpan", {
                                  attrs: { value: consumer.company_url },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        consumer.approval_status === 2
                          ? [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "xl:col-span-2 col-span-3 flex items-center justify-between text-xs pr-2",
                                },
                                [
                                  _vm.impersonatedUserUuid == consumer.uuid
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "px-3 py-1 bg-light-grey font-bold",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "pages.administrators.commons.exit_impersonate"
                                                )
                                              ) +
                                              " logined "
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "button",
                                        {
                                          staticClass:
                                            "px-3 py-1 bg-base-yellow font-bold",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.impersonatedLogin(
                                                "consumer",
                                                consumer.uuid
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "pages.administrators.commons.impersonate"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                  consumer.approval_status === 2
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "p-1 bg-brown-grey font-bold",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.nonapprovalConsumer(
                                                consumer.uuid
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "pages.administrators.svod_index.block"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          : consumer.approval_status === 1
                          ? [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "xl:col-span-2 col-span-3 flex items-center justify-between text-xxs pr-2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-span-1 text_item_start flex text-xs",
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "px-3 py-1 bg-green-400 font-bold",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.approvalConsumerModal(
                                                consumer
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "pages.administrators.svod_index.approve"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-span-1 text_item_start flex text-xs",
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "px-3 py-1 bg-brown-grey font-bold",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.nonapprovalConsumer(
                                                consumer.uuid
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "pages.administrators.svod_index.deny"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          : [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "xl:col-span-2 col-span-3 flex items-center justify-between text-xxs pr-2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-span-1 text_item_start flex text-xs",
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "px-3 py-1 bg-green-400 font-bold",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.approvalConsumerModal(
                                                consumer
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "pages.administrators.svod_index.approve"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                      ],
                      2
                    )
                  })
                : _vm._e(),
              _c("div", { ref: "RefScroll" }),
            ],
            2
          ),
        ],
        2
      ),
      _c("NoticeModal", {
        attrs: {
          modalName: "blockResult",
          contents: _vm.blockResultMessage,
          buttons: ["OK"],
        },
      }),
      _vm.consumer
        ? _c("NoticeModal", {
            attrs: {
              modalName: "approvalModal",
              title: "承認",
              contents:
                "本当に" +
                _vm.consumer.company_name +
                _vm.consumer.name +
                "を承認しますか？",
              buttons: ["キャンセル", "承認"],
            },
            on: {
              click: function ($event) {
                return _vm.approvalConsumer(_vm.consumer.uuid)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }